import React from 'react';

interface CardHeadingProps {
  children: React.ReactNode;
}

const CardHeading: React.FC<CardHeadingProps> = props => {
  return <h3 className="text-primary font-bold text-3xl">{props.children}</h3>;
};

export default CardHeading;
